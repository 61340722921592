.Button {
  border: 2px solid rgb(39, 28, 28);
  outline: none;
  cursor: pointer;
  /* margin: 1vh 0; */
  box-sizing: border-box;
  text-align: center;
  background-color: white;
  display: inline-block;
}

.Button a {
  color: inherit;
}
.Button:first-of-type {
  /* margin-left: 0;
  padding-left: 0; */
}

.Button:disabled {
  /* color: #ccc; */
  cursor: not-allowed;
}

.square {
  width: 100%;
  font-weight: bold;
  padding: 1.5vh 0;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.submit {
  /* border: 1px solid black; */
  width: fit-content;
  border-radius: 8px;
  padding: 6px 6px;
  font-weight: 600;
  font-size: 16px;
  margin: 0 0.5vw;
}
.rounded {
  /* border: 1px solid black; */
  min-width: fit-content;
  border-radius: 8px;
  padding: 6px 6px;
  font-weight: 600;
  font-size: 16px;
  margin: 2px 0.5vw;
}
.compact {
  height: fit-content;
  border-radius: 5px;
  padding: 1px 3px;
  margin: 2px 2px;
}

.category {
  min-width: 130px;
  border: 2px solid gray;
  border-radius: 7px;
  margin: 2px 0.5vw;
  padding: 1vh 1vw;
  font-weight: 600;
  /* user-select: none; */
}
.select {
  width: 100%;
  border: 2px solid gray;
  border-radius: 7px;
  padding: 10px;
  font-weight: 600;
  user-select: none;
}

.Selected {
  border-color: rgb(64, 0, 128);
  color: rgb(64, 0, 128);
}
.Selected:focus {
  border-color: rgb(64, 0, 128);
}

.Success {
  color: #5c9210;
}

.Disabled {
  background-color: #3d3c3c;
  color: rgb(255, 255, 255);
  cursor: not-allowed;
}

.Purple {
  background-color: rgb(64, 0, 128);
  color: white;
}

.delete {
  border-color: #8a0c0c;
  color: #8a0c0c;
  /* margin: 6px 0; */
  /* display: block; */
}
.Danger {
  color: #944317;
}

.Animation {
  animation: button 0.15s 1 ease-in-out;
}

.edit {
  border-color: #335306;
  color: #335306;
  background-color: white;
}

.wide {
  width: 100%;
}

.default {
  width: fit-content;
  font-weight: 600;
  background-color: rgb(199, 245, 245);
  border-color: rgb(102, 241, 241);
  color: rgb(26, 62, 62);
}
.grey {
  width: fit-content;
  font-weight: 600;
  background-color: rgb(235, 235, 235);
  border-color: rgb(171, 170, 170);
  color: rgb(55, 61, 61);
}

@keyframes button {
  0% {
    background-color: white;
  }

  50% {
    transform: scale(1.06);
    background-color: rgb(207, 207, 207);
  }

  100% {
    background-color: white;
  }
}
