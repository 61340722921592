/* eslint-disable */

.Wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background-color: rgb(255, 255, 255);
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 400;
  opacity: 1;
  transition: transform 0.3s ease-out;
  overflow-y: scroll;
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(100%);
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3vh 2vw;
}
.title {
  font-size: 1.4em;
  font-weight: 700;
}
.close {
  font-size: 1.2em;
}

.content {
  padding: 0vh 2vw;
}

.label {
  font-weight: 600;
}

.desc {
  color: grey;
}

.input {
  width: 100%;
  margin: 0.5vh 0;
  resize: none;
  border: 1px solid black;
  padding: 4px;
}

.inputWrapper {
  margin: 2vh 0;
}

.submit {
  text-align: end;
  padding: 3vh 2vw;
}

.ErrorMessage {
  height: 16px;
  color: rgb(155, 2, 2);
}

.dollar {
  padding-right: 1vw;
}

.pricingContent {
  display: flex;
}
.pricing {
  width: 70%;
  display: flex;
  align-items: center;
}
.currency {
  width: 30%;
  padding: 0 2vw;
  display: flex;
  align-items: center;
}

.copy {
  /* display: flex; */
}

.pricingContent {
  display: flex;
  justify-content: space-between;
  margin: 1vh 0;
}

.price {
  min-width: 100px;
}
.propertiesWrapper {
  display: flex;
  width: fit-content;
}

.properties {
  display: flex;
  /* flex-direction: column */
}

.edit {
  display: flex;
  flex-direction: column;
}

.imageWrapper {
  background-color: rgb(227, 227, 227);
  margin: 1vh 0;
}
.image {
  width: 40%;
  margin: 0 auto;
}

.split {
  display: flex;
  justify-content: space-between;
}

.paymentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .paymentWrapper label, */
.paymentWrapper input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}

.bold {
  font-weight: 600;
}

.text {
  color: gray;
}

.paymentWrapper input[type="radio"] + label {
  cursor: pointer;
  border: 2px solid grey;
  width: 100%;
  border-radius: 7px;
  margin: 2px;
  padding: 10px;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
}

.paymentWrapper input[type="radio"]:hover + label {
  background-color: rgba(230, 230, 230, 0.2);
}

.paymentWrapper input[type="radio"]:hover + label,
.paymentWrapper input[type="radio"]:focus + label,
.paymentWrapper input[type="radio"]:checked + label {
  border-color: rgb(64, 0, 128);
  color: rgb(64, 0, 128);
  transform: scale(1);
  box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.2);
}

.box {
  background-color: rgb(234, 244, 248);
  padding: 25px;
  border-radius: 14px;
}

.select {
  width: 100%;
  padding: 5px;
  border-radius: 6px;
}

.option {
  display: block;
}

.zIndex300 {
  z-index: 300;
  width: 90%;
}
.zIndex400 {
  z-index: 400;
  width: 85%;
}
.zIndex500 {
  z-index: 500;
  width: 80%;
}

.metadataRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.key {
  width: 40%;
}

.value {
  width: 65%;
}

.delete {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
