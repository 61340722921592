.paginate {
  display: flex;
  justify-content: space-between;
  padding: 0.5vh 1vw;
}

.left {
  display: flex;
  align-items: center;
}
.right {
  display: flex;
}
.right div {
  padding: 6px;
  border: 1px black solid;
  font-weight: 600;
  text-transform: capitalize;
}
.prev {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  user-select: none;
}
.next {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  user-select: none;
}

.disabled {
  color: grey;
}
