.app {
  min-height: 100dvh;
}

.container {
  display: flex;
  min-height: 100dvh;
  flex-direction: column;
  justify-content: space-between;
}

.disableScroll {
  position: fixed;
}

.page-wrapper {
  padding: 51px 0 0;
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.page-title {
  text-align: center;
  font-size: 48px;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 900;
  user-select: none;
  padding: 1vh 0;
}

/**********************************************************************
* Modals **************************************************************
**********************************************************************/
.modal-title {
  font-size: 1.2em;
  font-weight: 600;
  padding: 2vh 4vw;
}

.modal-selection {
  display: flex;
  padding: 2vh 4vw;
}

.modal-button {
  height: 50px;
  cursor: pointer;
  width: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}

.modal-message {
  padding: 2vh 4vw;
}

.modal-cancel {
  background-color: black;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
}

a {
  text-decoration: none;

  color: black;
}
/* 
.page-body {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}


.page-title a {
  color: black;
  text-decoration: none;
}

.header {
  padding: 1vh 2vw;
}

.ItemFull {
  width: 100% !important;
  align-content: space-around !important;
}

.ItemFull a {
  padding: 4vh 0;
}

.ItemFullColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.material-icons {
  font-size: 16px;
} */

@media only screen and (min-width: 600px) {
  /* For tablets: */
}

@media only screen and (min-width: 1025px) {
  /* For Desktop: */
}
