* {
  margin: 0;
  font-family: Lato, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
}

/* Text */
.title {
  text-transform: uppercase;
}
.subTitle {
  text-transform: uppercase;
}
.heading {
  font-size: 1.4em;
  font-weight: 700;
}
.subHeading {
  font-size: 1.2em;
}
.body {
  font-size: 1em;
}
.bold {
  font-weight: 600;
}
/* Inputs */

.inputWrapper {
  position: relative;
  padding: 0;
  /* top: 51px; */
}

.input {
  width: 100%;
  padding: 4px 6px;
  margin: 0.5vh 0;
  border-radius: 4px;
  /* border-color: grey; */
}

.inputSearch {
  margin: 2%;
  /* display: inline-block; */
  position: absolute;
  width: 96%;
  padding: 8px;
  top: 0;
  z-index: 50;
  box-sizing: border-box;
}

.flex {
  display: flex;
  flex-direction: row !important;
  align-items: center;
}

/* Colors */
.primaryColor {
  color: black;
}
.primaryColor200 {
  color: grey;
}
.secondaryColor {
  color: rgb(64, 0, 128);
}
.terciaryColor {
  color: white;
}

.auth-btn {
  background-color: #6623d3;
}
.my-auth-btn {
  color: #6623d3;
}
.auth-btn:hover {
  background-color: #5b1fbd;
}

.btn-primary {
  background-color: #357ebd;
}
.my-btn-primary {
  color: #357ebd;
}
.btn-primary:hover {
  background-color: #2f6ea5;
}

.btn-info {
  background-color: #5bc0de;
}
.my-btn-info {
  color: #5bc0de;
}
.btn-info:hover {
  background-color: #50a6c0;
}

.btn-danger {
  background-color: #d9534f;
}
.my-btn-danger {
  color: #d9534f;
}
.btn-danger:hover {
  background-color: #cc4f4b;
}
