.Home {
  overflow: hidden;
}

.headerWrapper {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.headerTitle {
}

.imageBackgroundWrapper {
}

.imageBackgroundWrapper img {
  width: 100%;
  object-fit: cover;
}

.categories {
  width: 96%;
  margin: 0 2% 2%;
  height: 40%;
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 2%;
}

.category {
  background-color: rgb(33, 17, 17);
  height: 100%;
  width: 25%;
  border: 1vw solid black;
  overflow: hidden;
}

.imageWrapper {
  display: flex;
  align-items: center;
  height: 80%;
  overflow: hidden;
}
.category img {
  width: 100%;
}

.categoryTitle {
  padding: 0.5vh 0;
  border-bottom: 1vw solid black;
  height: 20%;
  font-size: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.header {
}

.title {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-weight: 900;
  position: absolute;
  top: 30%;
  right: 2%;
  text-shadow: -3px 3px 1px rgba(0, 0, 0, 1), -10px 2px 12px rgba(68, 0, 0, 1);
}

.products {
  /* margin: 0vh 2vw; */
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  padding: 0 2%;
}

.statement {
  text-align: center;
  background-color: aquamarine;
  /* margin: 0 0 3vh; */
  padding: 2vh 0;
  font-size: 16px;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
}

@media only screen and (min-width: 1025px) {
  /* For desktop: */
  .imageBackgroundWrapper {
    height: calc(100vh - 51px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imageBackgroundWrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  /* .Home {
  }
  .centered {
    width: 570px;
    padding: 3vh 2vw;
    top: 40%;
    left: 30%;
  }

  .shop {
    padding: 1vh 1vw;
  }
  .shop a b {
    font-size: 32px;
  }
  .description {
    font-size: 32px;
  }
  .title {
    font-size: 48px;
  } */
}
