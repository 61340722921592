.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 2vw 0vh;
}

.close {
  cursor: pointer;
  white-space: nowrap;
}
