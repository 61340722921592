.table {
  font-weight: 900;
  align-items: end;
}
.itemWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid grey;
  box-sizing: border-box;
}

.imageWrapper {
  width: 15vw;
  display: flex;
  align-items: center;
  padding: 0.5vh 0.5vw;
}

.name {
  text-transform: capitalize;
}

.image {
  width: 15vw;
}

.details {
  width: 25vw;
  padding: 0.5vh 2vw;
  box-sizing: border-box;
}

.Created {
  width: 20vw;
  padding: 0.5vh 1vw;
  box-sizing: border-box;
  /* text-transform: uppercase; */
}

.Updated {
  width: 20vw;
  padding: 0.5vh 1vw;
  box-sizing: border-box;
  /* text-transform: uppercase; */
}
.created {
  width: 20vw;
  padding: 0.5vh 1vw;
  box-sizing: border-box;
  text-transform: capitalize;
}

.updated {
  width: 20vw;
  padding: 0.5vh 1vw;
  box-sizing: border-box;
  text-transform: capitalize;
}

.editWrapper {
  width: 20vw;
  padding: 0.5vh 0.5vw;
  box-sizing: border-box;
}

.tooltip {
  display: "inline-block";
  margin: "60px";
  color: black;
}

.archived {
  width: fit-content;
  background-color: rgb(215, 215, 215);
  border: 1px black solid;
  border-radius: 10px;
  text-align: center;
  padding: 1px 6px;
  margin: 2px;
}

.disabled {
  color: grey;
}
