.Metadata {
  padding: 0 2vw;
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 0;
}

.left {
  text-transform: uppercase;
  font-weight: 900;
}

.label {
  width: 150px;
  color: rgb(85, 85, 85);
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.SubmitBar {
  display: flex;
  justify-content: space-between;
  margin: 1vh 0;
}

.Form form {
  border-bottom: 1px black solid;
  margin: 0 0 2vh 0;
}

.Metadata input {
  margin: 0.5vh 0;
  resize: none;
  border: 1px solid black;
  padding: 4px;
  box-sizing: border-box;
  font-size: inherit;
}

.inputFake {
  margin: 0.5vh 0;
  resize: none;
  border: 1px solid transparent;
  padding: 4px;
  box-sizing: border-box;
}

.key {
  width: 40%;
}

.value {
  width: 65%;
}

.delete {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
