.Catalog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.copy {
  text-align: start;
}
@media only screen and (min-width: 600px) {
  /* For tablets: */
}

@media only screen and (min-width: 1025px) {
  /* For desktop: */
}
