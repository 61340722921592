.HeaderWrapper {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* color: gray; */
  /* padding: 1vh 1vw; */
}

.left {
  width: 50%;
  font-size: 1rem;
  text-transform: uppercase;
  display: flex;
  color: black;
  cursor: pointer;
}

.price {
  color: rgb(70, 70, 70);
}
.right {
  width: 50%;
  padding: 0 2vw;
  /* font-size: 1.1rem; */
  /* text-transform: uppercase; */
  display: flex;
  flex-direction: column;
  color: black;
}

.thumbnail {
  /* width: 50%; */
  display: flex;
  align-items: center;
}

.details {
  text-transform: capitalize;
  padding: 0 2px;
}

.name {
  /* text-align: center; */
  font-size: 1em;
  font-weight: 900;
  text-transform: uppercase;
  /* height: auto; */
  /* margin: 0.5vh 0; */
}

.copy {
  /* margin: 0.5vh 0; */
}

.editBar {
  display: flex;
  flex-direction: column;
}

.delete {
  color: red;
  /* user-select: none; */
  /* margin: 0.5vh 0; */
}

.unavailable {
  color: grey;
}

.archived {
  width: fit-content;
  background-color: rgb(215, 215, 215);
  border: 1px black solid;
  border-radius: 10px;
  text-align: center;
  padding: 1px 6px;
  margin: 2px;
}
