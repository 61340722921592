.ReviewContianer {
  color: white;
}

.rating {
  color: #ffb800;
  margin: 1vh 0;
}

.review {
  color: white;
  text-align: start;
}

.data {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1vh 0;
}

.username {
  color: white;
  padding: 1vh 4px;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
}

.date {
  padding: 1vh 0px;
  color: #676969;
  font-size: 1.1em;
}
