.Item {
  width: 100vw;
  height: auto;
}

image {
  height: 30px;
}
.inputWrapper {
  padding: 0;
}

.input {
  width: 100%;
  padding: 4px 6px;
  margin: 0.5vh 0;
  border-radius: 4px;
}

.inputSearch {
  border: 2px solid black;
  border-radius: 6px;
  margin: 2% 2% 0;
  width: 96%;
  padding: 8px;
  box-sizing: border-box;
}
.categoryNavWrapper {
  position: relative;
  margin: 0 2%;
}
.categoryNav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.category {
  white-space: nowrap;
  background-color: aqua;
  padding: 2vh 1vw;
}

.banner {
  margin: 2% 0;
  background-color: rgb(44, 12, 65);
  color: #fff;
  padding-right: 2%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: right;
  text-transform: capitalize;
}

.bannerText {
}

.storeWrapper {
  display: flex;
  flex-grow: 4;
  height: 100%;
  flex-wrap: wrap;
  padding: 0 2%;
  gap: 2%;
}
