.Cart {
  margin: 0 0 5vh;
}

.Item {
  padding: 2vh 0;
  /* margin: 0 0 2vh; */
  border-bottom: grey 1px solid;
}

.ItemData {
  display: flex;
}

.ImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4vw;
  cursor: pointer;
  width: 250px;
}

.ImageWrapper img {
  max-width: 150px;
}

.Details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Name {
  font-weight: 600;
  font-size: 1.2em;
  text-transform: uppercase;
  padding: 2px 0;
}

.Description {
  padding: 2px 0;
}

.PriceWrapper {
  padding: 0.5vh 4vw;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.Pricing {
  display: flex;
  align-items: center;
}

.QuantityWrapper {
  padding: 1vh 4vw;
  font-size: 0.9rem;
  width: 250px;
  display: flex;
  justify-content: center;
}

.Quantity {
  display: flex;
  align-items: center;
  /* margin: 0; */
}

.QuantityModifierL {
  border: 1px solid black;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  /* font-weight: 900; */
  padding: 1vh 2vw;
  cursor: pointer;
  background-color: rgb(241, 241, 241);
}

.QuantityModifierR {
  border: 1px solid black;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  /* font-weight: 900; */
  padding: 1vh 2vw;
  cursor: pointer;
  background-color: rgb(241, 241, 241);
}

.QuantityAmount {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  /* background-color: rgb(241, 241, 241); */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vh 16px;
  /* font-size: 1.2em; */
}

/* Bars */
.Bar {
  margin: 2vh 0;
  height: 30px;
  border-radius: 3% / 50%;
  background-color: rgb(214, 214, 215);
}

/* Order Summary */

.OrderSummary {
  display: flex;
  justify-content: flex-end;
}

.OrderDetails {
  text-align: start;
  width: 100%;
}

.OrderDetailsSection {
  display: flex;
  padding: 15px 4vw;
  justify-content: space-between;
  border-bottom: 1px solid grey;
}

.SubtotalLabel,
.ShippingLabel {
  color: grey;
}

.OrderDetailsTotal {
  display: flex;
  justify-content: space-between;
  padding: 15px 4vw;
  font-weight: 600;
}

.Checkout {
  padding: 15px;
  /* background-color: #221a00; */
  background-color: black;
  color: white;
  text-align: center;
  font-size: 1.5em;
  font-weight: 900;
  cursor: pointer;
}

.buttonsWrapper {
  display: flex;
}
.Button {
  /* width: 50%; */
  /* padding: 2vh 2vw; */
  box-sizing: border-box;
  cursor: pointer;
}

.Options {
  display: flex;
  width: 100%;
  padding: 1vh 0;
  font-size: 1rem;
}
.Remove {
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 15px;
  /* font-weight: 600; */
  padding: 1vh 2vw;
  cursor: pointer;
  background-color: rgb(241, 241, 241);
  /* font-size: 1.1em; */
  /* overflow: hidden; */
  /* width: 100%; */
}

.save {
  width: fit-content;
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 15px;
  padding: 1vh 2vw;
  cursor: pointer;
  margin-left: 2vw;
  /* background-color: rgb(241, 241, 241); */
  background-color: #3d3c3c;
  color: white;
}
.delete {
  padding-left: 2vw;
  display: flex;
  /* font-size: 1.1rem; */
  align-items: center;
}

.buttonsWrapper {
  display: flex;
}
.Button {
  width: 50%;
  padding: 2vh 2vw;
  box-sizing: border-box;
  cursor: pointer;
}
.RemoveButton {
  background-color: black;
  color: white;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .Item {
    /* display: flex;
    justify-content: space-between; */
  }
  .CardThumbnail img {
    /* max-height: 100px;
    max-width: 130px; */
  }

  .ItemData {
    /* width: 60%; */
  }
  .Pricing {
    /* width: 40%; */
  }
  .Bar {
    /* border-radius: 1% / 50%; */
  }
}

@media only screen and (min-width: 1025px) {
  /* For desktop: */
  .CardThumbnail img {
    /* max-height: 130px;
    max-width: 160px; */
  }

  .Cart {
    /* margin: 0 15vw 5vh; */
  }
}
