.SidebarWrapper {
}

.Sidebar {
  position: fixed;
  width: 280px;
  max-width: 70%;
  left: 0;
  top: 0;
  z-index: 400;
  background-color: rgb(255, 255, 255);
  /* background-color: rgb(4, 0, 10); */
  padding: 0 1vw;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  text-align: center;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  cursor: pointer;
}

.SidebarItemWrapper {
  color: white;
  margin: 1vh 0.25vw;
  box-sizing: border-box;
}

.SidebarItemLabel {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid black;
  padding: 1.5vh 0;
  text-transform: uppercase;
  font-size: 0.95rem;
  font-weight: 600;
  color: dimgray;
}
.SidebarItem {
  font-weight: 600;
  padding: 1.5vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  border-bottom: 1px solid black;
  font-size: 0.95rem;
}

.SidebarItem:hover,
.active {
  background-color: lightgray;
}

.SidebarItem:hover,
.active {
  background-color: buttonface;
}

.LogoWrapper {
  box-sizing: border-box;
  padding: 25px;
}

/* @media only screen and (min-width: 1025px) {
    
} */

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-100%);
}
