.SidebarItem {
  font-weight: 600;
}
.SidebarItem a {
  /* font-size: 1.25rem; */
  padding: 1.5vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  border-bottom: 1px solid black;
  font-size: 0.95rem;
}

.SidebarItem:hover,
.active {
  background-color: buttonface;
}

/*
@media only screen and (min-width: 600px) {
    .SidebarItem {
        padding: 5px 0;
    }
}

@media only screen and (min-width: 1025px) {    
    .SidebarItem a:hover,
    .SidebarItem a.active {
        border-bottom: 1px solid #afe4f7;
    }
} */
