.modalWrapper {
  position: absolute;
  height: 100%;
  overflow-y: auto;
}

.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  border: 1px solid rgb(27, 16, 6);
  /* padding: 2vh 5vw 2vh; */
  margin: 0 4vw;
  left: 0;
  top: 25%;
  box-sizing: border-box;
  transition: all 0.25s ease-out;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .Modal {
    /* left: calc(50% - 250px);*/
    /* left: 35%; */
  }
}
