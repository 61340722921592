.datesHeader {
  display: flex;
  font-weight: 900;
  font-size: 1em;
  text-transform: uppercase;
}

.details {
  text-transform: capitalize;
  padding: 0 2px;
}
.detailsWrapper {
  display: flex;
  padding: 1vh 2vw;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.detailsLeft {
  font-weight: 900;
  text-transform: uppercase;
}
.detailsRight {
  /* font-weight: 600; */
}

.row {
  display: flex;
  margin: 1vh 0;
  box-sizing: border-box;
}

.label {
  width: 35%;
  color: rgb(85, 85, 85);
  box-sizing: border-box;
}

.input {
  width: 65%;
  box-sizing: border-box;
}

.Currency {
  color: rgb(36, 36, 36);
  /* font-size: 0.9em; */
  text-transform: uppercase;
  font-weight: 600;
}

.Content {
  padding: 0 2vw;
  box-sizing: border-box;
}

.features {
  /* display: block; */
}
