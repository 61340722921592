.Item {
  width: 49%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  text-decoration: none;
  color: black;
  padding: 2% 0;
}

.Item a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.MaterialIcons {
  cursor: pointer;
  user-select: none;
}

.Remove {
  /* grid-area: del;
    display: flex;
    text-align: center;
    align-items: center;
    margin: auto;  */
}

.Thumbnail {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgb(240, 240, 240);
}
.Thumbnail a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Thumbnail img {
  width: 105%;
  background-size: cover;
  transition: transform 0.2s;
}
.Thumbnail img:hover {
  transform: scale(1.05);
}

.Name {
  padding: 1px 0;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 800;
  text-transform: uppercase;
  word-wrap: break-word;
}

.Description {
  padding: 1px 0;
  text-align: center;
  font-size: 1.1rem;
}

.PriceWrapper {
  padding: 2px 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.Price {
  padding: 0 2px;
}
.Currency {
  padding: 0 2px;
  text-transform: uppercase;
}

.SelectWrapper {
  border: rgb(0, 0, 0) solid 1px;
  background-color: rgb(238, 238, 238);
  cursor: pointer;
}
.SelectWrapper:hover {
  background-color: rgb(210, 218, 218);
}

.Select {
  font-size: 16px;
  padding: 1vh 0;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .Item {
    width: 32%;
  }
}

@media only screen and (min-width: 1025px) {
  /* For desktop: */
  .Item {
    width: 23%;
  }
}
