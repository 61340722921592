.ImageSlider {
  position: relative;
  height: fit-content;
  box-sizing: border-box;
  overflow: hidden;
  color: black;
  user-select: none;
}

.SlideWrapper {
  /* margin: 0 auto; */
  /* width: 100vw; */
  /* height: auto; */
  /* border-radius: 10px; */
  box-sizing: border-box;
  overflow: hidden;
}

.Slide {
  width: 100%;
  /* height: 300px; */
  /* border-radius: 10px; */
  /* background-size: cover; */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
}

/* .Arrow:hover {
    color: rgb(116, 116, 116);
} */

.Arrows {
  box-sizing: border-box;
  overflow: hidden;
  font-size: 2.5em;
  user-select: none;
  cursor: pointer;
}
/********************************************
Right Arrow animation 
********************************************/

.ArrowWrapperLeft {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0px;
  display: flex;
  padding-left: 2px;
  box-sizing: border-box;
  overflow: hidden;
}

.ArrowWrapperLeft span {
  /* font-size: 4em; */
  width: auto;
  margin: auto -3px;
}
.Animated {
  animation: animate 0.75s 1 ease-in-out;
}

.ArrowWrapperLeft span:nth-child(2) {
  animation-delay: -0.1s;
}

.ArrowWrapperLeft span:nth-child(3) {
  animation-delay: -0.2s;
}

@keyframes animate {
  0% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  50% {
    opacity: 0;
    transform: translate(-25px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
/********************************************
Right Arrow animation 
********************************************/
.ArrowWrapperRight {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0px;
  display: flex;
  padding-right: 2px;
  justify-content: right;
  /* overflow: hidden; */
  /* transform: translateX(50%); */
}
.AnimatedRight {
  animation: animateRight 0.75s 1 ease-in-out;
}
.ArrowWrapperRight span {
  overflow: hidden;
  box-sizing: border-box;
  /* font-size: 2em; */
  width: auto;
  margin: auto -3px;
  animation-delay: -0.2s;
}

.ArrowWrapperRight span:nth-child(2) {
  box-sizing: border-box;
  animation-delay: -0.1s;
}

.ArrowWrapperRight span:nth-child(3) {
  box-sizing: border-box;
  animation-delay: 0s;
}

@keyframes animateRight {
  0% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  50% {
    opacity: 0;
    transform: translate(25px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

/*******************************************
 * DOTS 
*******************************************/
.Dots {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0.5%;
  left: 0;
  right: 0;
  overflow: hidden;
}

.Dots span {
  /* margin: 0 4px; */
  cursor: pointer;
  font-size: 1.75em;
  user-select: none;
  transition: color, 0.2s;
  box-sizing: border-box;
  overflow: hidden;
}

.ActiveDot {
  color: rgb(151, 149, 149);
  box-sizing: border-box;
}

.AnimatedDot {
  animation: animatedDot 0.15s linear;
}
@keyframes animatedDot {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.65;
    transform: scale(1.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* .Dot:hover {
  color: rgb(151, 149, 149);
  box-sizing: border-box;
} */

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .SlideWrapper {
    /* margin: 0 auto; */
    /* width: 500px;
    height: 500px; */
    /* border-radius: 10px; */
    /* box-sizing: border-box; */
  }
}

@media only screen and (min-width: 1025px) {
  /* For desktop: */
}
