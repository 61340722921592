.Auth {
    text-align: center;
    align-items: center;
    background-color: rgb(18, 21, 39);
    margin: 2vh 2vw;
    padding: 1vh 5vw 5vh;
    color: white;
}
.Auth a { 
    z-index: 1;     
    text-decoration: none;
    color: white;
}


.AuthInput {
    color: rgb(255, 255, 255) !important;   
    width: 100%;
    height: 34px;
    margin: 2vh 0;
    background-color: transparent !important;
    border: none;
    border-bottom-color:rgb(23, 4, 53);
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

.AuthInput2 {
    color: rgb(255, 255, 255) !important;   
    width: 100%;
    height: 34px;
    margin: 2vh 0;
    background-color: transparent !important;
    border: none;
    border-bottom-color: transparent;
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

.Btn {
    color: rgb(255, 255, 255);   
    width: 100%;
    margin: .5vh 0;
    cursor: pointer;
}

.BtnDiv {
    padding: .5em 0;
}

.AuthNav {
    width: 100%;
    display: flex;
}

.AuthToggle {
    border: none;
    width: 50%;
    background-color: transparent;
    color: rgb(23, 4, 53);
    color: rgb(255, 255, 255);   
    font-weight: bold;
    white-space: nowrap;
    border-bottom-color: transparent;
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

.AuthSelected {
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-width: 3px;
    border-bottom-style: solid;
}


#inputId:-webkit-autofill {
    background-color: black !important;
}

.eye {
    margin: auto 2vw;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    .Auth {
        /* margin: 6vh 20vw 6vh; */
    }
}

@media only screen and (min-width: 1025px) {
    /* For desktop: */
    .Auth {
        /* margin: auto 30vw; */
      
    }
}
